<script lang="ts" setup>
import { useLeaseReviewWebViewer } from '../Review/composables'
import { useUnlinkHandler, type IDocument } from '@register'

const props = defineProps<{ documents: IDocument[] }>()

const leaseId = getCurrentLeaseId()
const dayjs = useDayjs()
const { dateFormat: divisionDateFormat } = useDivisions()
const { currentDocumentId, changeDocument } = useLeaseReviewWebViewer()

const onUnlinkHandler = useUnlinkHandler()
const onUnlink = (document: IDocument) =>
  onUnlinkHandler({ documentId: document.id })

const generateTitle = (obj: IDocument, idx: number) => {
  if (
    idx === 1 &&
    obj.type === 'lease' &&
    props.documents[0].type === 'sublease'
  ) {
    return 'Headlease'
  }
  return idx === 0 ? `This ${title(obj.type)}` : title(obj.type)
}
</script>

<template>
  <div v-auto-animate="{ duration: 100 }" role="list">
    <!-- Documents loop -->
    <div
      v-for="(obj, idx) in documents"
      :key="obj.id"
      role="button"
      tabindex="0"
      class="hover:text-primary flex cursor-pointer items-center text-sm"
      :class="{
        'triangle text-primary rounded-md bg-gray-700':
          obj.id === currentDocumentId,
      }"
      @click="changeDocument(obj.id)"
      @keyup.enter="changeDocument(obj.id)"
    >
      <!-- Type -->
      <div class="w-[126px] p-4">
        {{ generateTitle(obj, idx) }}
      </div>

      <!-- Filename -->
      <div class="flex-grow p-4">
        {{ obj.filename }}
      </div>

      <!-- Created At -->
      <div class="flex-shrink p-4">
        <time>
          {{ dayjs(obj.createdAt).format(divisionDateFormat) }}
        </time>
      </div>

      <div class="w-12 p-4">
        <ActionMenu
          v-if="obj.canUnlink || obj.canDelete"
          class="relative"
          @click.stop="
            () => {
              // Workaround to stop click event and prevent document change
            }
          "
        >
          <ActionMenuItem
            class="text-red hover:bg-red hover:text-red-50"
            @click="onUnlink(obj)"
          >
            {{ obj.canUnlink ? 'Unlink' : 'Delete' }}
          </ActionMenuItem>
        </ActionMenu>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.triangle {
  position: relative;
  &::before {
    @apply absolute -right-2 h-0 w-0 border-solid content-[''];
    border-width: 20px 0 20px 12px;
    border-color: transparent transparent transparent #4d4d4d;
  }
}
</style>
